import React  from 'react';
import './Login.css';
import './Popup.css';
import {Form, FormGroup, Button, Col, Image, Row } from 'react-bootstrap'
import  'typeface-roboto'
import Portada from '../../Images/Portada.png';
import Logo from '../../Images/Logo.svg';
import queryString from 'query-string';
import Popup from 'react-popup';

const Sessions =()=>{
    React.useEffect(() => {
        finishSession();
    }) 
    return null;
}
const LoginForm = ({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
        <div className="form-content">
           <Popup
            className="mm-popup"
            btnClass="mm-popup__btn"
            closeBtn={true}
            closeHtml={null}
            defaultOk="Ok"
            defaultCancel="Cancel"
            wildClasses={false}
            escToClose={true} />
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6} className="doc">
                    <div className="box" >
                        {/*<Image src={require("../../Images/Portada.png")} className="full-height" alt="Talento Fragua"/>*/}
                        <Image src={Portada} className="full-height" alt="Talento Fragua"/>
                    </div>                            
                </Col>
                <Col xs={12} sm={12} md={4} lg={5} xl={6} >
                    <Form.Group className="Logo"  > 
                        {/*<Image src={require("../../Images/Logo.svg")} className="imgLogo" /> */}                           
                        <Image src={Logo} className="imgLogo" />                            
                    </Form.Group>
                    <Form onSubmit ={handleSubmit}>  
                        <Form.Group className="form-input"> 
                            <Form.Control  
                            className={"Input"}
                            name="username" 
                            type="text" 
                            placeholder="Usuario" 
                            value={values.username} 
                            onChange={handleChange}
                            onBlur={handleBlur}/>                                        
                        </Form.Group>
                        <Form.Group className="form-input"  > 
                            {errors.username && touched.username && (
                                <FormGroup className="input-feedback">{errors.username}{values.errors=' '}</FormGroup>                                
                            )}                                        
                                <span className="input-feedback">{values.errors ? values.errors: ''}</span>
                            </Form.Group>
                        <Form.Group  className="form-input" > 
                            <Form.Control  
                                className="Input"
                                name="password" 
                                type="password" 
                                placeholder="Contraseña" 
                                value={values.password} 
                                onChange={handleChange}
                                onBlur={handleBlur}/>
                        </Form.Group>
                        <Form.Group className="form-input"  > 
                            {errors.password && touched.password && (
                                <FormGroup className="input-feedback">{errors.password}</FormGroup>
                            )}
                            </Form.Group>
                        <Form.Group  className="form-width">
                            { isSubmitting 
                                ? <Button className="boton" variant="primary" type="submit" disabled> 
                                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  </Button> 
                                : <Button className="boton" variant="primary" type="submit">INICIO</Button> 
                            } 
                                                                                                  
                        </Form.Group>  
                        <Form.Group className="message"  id="mensaje"></Form.Group>
                    </Form>
                </Col>                        
            </Row>
            <Sessions/>  
         </div>                    
    ) 
export default LoginForm;


function finishSession(){
    const URLDestl = queryString.parse(window.location.search);
    const type = URLDestl.type;
    if(type==1)
    {
        Popup.create({
            title: 'Cierre de sesión exitoso',
            content: 'Se ha cerrado su sesión.',
            className: 'alert',
            buttons: {
                right: [{
                    text: 'Ok',
                    className: 'success',
                    action: function () {
                        Popup.clearQueue();                        
                        var loc= window.location.origin;
                        window.location =loc+process.env.PUBLIC_URL;
                        Popup.close();
                    }
                }]
            }
        }, true);
    }
    else if(type == 2){
       Popup.create({
            title: 'Tu sesión ha expirado',
            content: 'Vuelva a iniciar sesión para continuar.',
            className: 'alert',
            buttons: {
                right: [{
                    text: 'Ok',
                    className: 'success',
                    action: function () {
                        Popup.clearQueue();
                        var loc= window.location.origin;
                        window.location =loc+process.env.PUBLIC_URL;
                        Popup.close();
                    }
                }]
            }
        }, true);
    }
}