
import React, { useEffect } from 'react';
import './Redirect.css';
import Logo from '../../Images/Logo.svg';

const RedirectUrl = (props) => { 
    const formRef = React.createRef();
    useEffect(() => {
      formRef.current.submit();
    });

    return (
        <form ref={formRef} method="POST" action={props.location.state.url}>
            <input type="hidden" name="key" value={props.location.state.token}/>
            <input type="hidden" name="ouid" value={props.location.state.ouid}/>           

            <div className="divContener" >
                <div className="row">
                    <div className="divCenter">    
                    <div className="divImg"  >                                                   
                            <img src={Logo} className="img-fluid" />                            
                        </div>             
                        <div className="row divText">
                        
                            <h1 className="text-center text-primary">Cargando</h1>
                        </div>
                        <div className="spinner-border _progress text-primary"></div>                         
                    </div>
                </div>
            </div> 
           
        </form>
    );
}

export default RedirectUrl;