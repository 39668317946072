import React, { Component } from "react";
import {Formik} from 'formik';
import * as Yup from 'yup'; 
import axios from "axios";
import {withRouter} from 'react-router-dom'
import LoginForm from "./LoginForm";
import './Login.css';
import queryString from 'query-string';


class Login extends Component{    
    render(){        
        return(         
            <Formik initialValues ={{ username : "", password: "", errors: "", response: ""}}

            onSubmit={(values, {setSubmitting}) => {
                setTimeout (() => {
                    const val= validationUsername(values); 
                    const { history } = this.props;
                    var mensaje = document.getElementById('mensaje')   
                    
                    if(!val){
                        values.errors ="Usuario incorrecto";
                        setSubmitting(false);
                    } 
                    else{                                                                    
                        var URLDest = window.location.search;                                     
                        var dest="";
                        if(URLDest.includes('dest')){
                            const URLDest1 = queryString.parse(window.location.search);
                            const parametro = URLDest1.dest;
                            dest =parametro;
                        } 
                        var data = JSON.stringify({"user":values.username,"password":values.password,"urlDest":dest});                                                                                    
                        var config = {
                            method: 'post',
                            url: process.env.REACT_APP_URL_WS,
                            headers: { 
                                'Content-Type': 'application/json'
                            },
                            data : data
                        };                
                        axios(config)
                        .then(function (response) {
                            if(response.status===200) {                    
                            var token = response.data.data.token;
                            var url = process.env.REACT_APP_URL_CORNERSTONE;
                            var ouId = response.data.data.ouId;
                            let data ={token: token, url: url, ouid: ouId};  
                            history.push({
                                    pathname: '/RedirectUrl',
                                    state : data
                                });
                            }
                            setSubmitting(false);
                        })
                        .catch(error => {
                            if (error.response) {  
                                if (error.response.data){
                                    mensaje.innerHTML = error.response.data.msg;
                                } else {
                                    mensaje.innerHTML = error;
                                }                               
                            } else if (error.request) {
                                if(error.request.status ===0){
                                    mensaje.innerHTML = "Error: El servidor no se encuentra accesible.";
                                }else{
                                mensaje.innerHTML = error;
                                }
                            } else {
                                mensaje.innerHTML = error;
                            }
                            setSubmitting(false);                         
                        });                      
                    }
                   
                }, 800);
            }}

            validationSchema ={Yup.object().shape({
                username: Yup.string()
                .required("Ingrese su usuario"),
                password: Yup.string()
                .required("Ingrese su contraseña")
            })
        }
        >
        {
            props => <LoginForm {...props}/>
        }
        </Formik>
        );
    }
}
function validationUsername(values) {
    const userPatternEmail = /^[0-9a-zA-Z\._-ñÑ]+@[0-9a-zA-Z_-]+(\.[a-z]+)+$/;
    const userPatternCode = /^[0-9]+$/;
    if(!userPatternCode.test(values.username)){
        if(!userPatternEmail.test(values.username)){                   
            return false ;
        } 
    } 
    return true
}

export default withRouter(Login);