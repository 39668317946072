import React, { Component } from 'react';
import './App.css';
import Login from './components/login/Login';
import Redirect from './components/login/RedirectUrl';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'


class App extends Component{
  render(){
    return(
      <div className="Apps">
      <Router>
                 
          <Switch>
              <Route path='/RedirectUrl' component={Redirect} />
              <Route path='/' component={Login} />
          </Switch>
       
      </Router>    
      </div> 
    )
  }
}

export default App;

 